export default {
    default: () => import('@/pages/default'),
    'catalog_product_view': () => import('@/pages/product-page'),
    'wishlist_index_configure': () => import('@/pages/product-page'),
    'kinderkraft_safetycarform_index_form': () => import('@/pages/safety-car-form-page'),
    'catalog_category_view': () => import('@/pages/product-listing'),
    'catalogsearch_result_index': () => import('@/pages/product-listing'),
    'cms_page_view': () => import('@/pages/product-listing'),
    'blog_post_view': () => import('@/pages/product-listing')
}
