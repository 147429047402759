import Intersect from '@alpinejs/intersect';
import Alpine from 'alpinejs';

Alpine.plugin(Intersect);

window.Alpine = Alpine;

export const initAlpine = () => {
    Alpine.start();
}
