export default class GlobalConfigService {
    constructor() {
        this._config = {};
    }

    get config() {
        return this._config;
    }

    set config(payload) {
        const key = payload.key;
        const data = payload.data;

        if (key && data) {
            this._config[key] = data;
        }
    }

    getConfigByKey(key) {
        if (!key || typeof key !== 'string') {
            return this._config;
        }

        return this._config[key];
    }
}
