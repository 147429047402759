window.__webpack_public_path__ = window.webpack ? window.webpack.publicPath : '';
import { initGlobals } from '@/shared/utils/get-globals';
import { initAlpine } from '@/shared/utils/get-alpinejs';
import PagesMap from '@/pages-map';

window.appBootstrap = function (pageType, context = null, loadDefault = true) {
    const importPromises = [];

    initGlobals();

    if (loadDefault) {
        importPromises.push(PagesMap.default());
    }

    if (pageType && PagesMap[pageType]) {
        importPromises.push(PagesMap[pageType]());
    }

    window.addEventListener('DOMContentLoaded',() => {
        Promise.all(importPromises)
            .then(imports => {
                imports.forEach(imported => imported.default.load(context));
            })
            .then(() => {
                initAlpine();
            });
    });
}
