export default class Translations {
    _data = null;

    constructor(translations = {}) {
        this._data = translations;
    }

    set(translations = {}) {
        if (typeof translations === 'object') {
            this._data = {
                ...this._data,
                ...translations
            }
        }
    }

    get(key) {
        if (!key) {
            return this._getAll();
        }

        const parts = key.split('.');
        let result = null;

        parts.some((item, index) => {
            const translation = index ? result[item] : this._getAll()[item];

            if (typeof translation === 'undefined') {
                return true;
            }
            result = translation;
        });

        return result || '';
    }

    _getAll() {
        return this._data;
    }
}
